export function trimDecimalsOverflow(n: string, decimals: number) {
  n += '';

  if (n.indexOf('.') === -1) return n;

  const arr = n.split('.');
  const fraction = arr[1].substr(0, decimals);

  return arr[0] + '.' + fraction;
}
