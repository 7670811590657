import {
  useAddress,
  useContract,
  useTransferToken,
  useSDK
} from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { Percent } from '@uniswap/sdk-core';
import { Router } from '@uniswap/v2-sdk';
import { useAsyncFn } from 'react-use';

import routerAbi from 'abis/router.json';
import wethAbi from 'abis/weth.json';

import {
  decToFrac,
  numberToBigNumberFixed,
  parseUnits
} from '@app/helpers/format';
import { getSwapSettings } from '@app/hooks/swap/useSwapSettings';
import { Token } from '@app/types/token';
import { ModeEnvs } from '@app/constants/chains';
import { USTrade } from '@app/types/trade';
import { useConfig } from '@app/config';
import { trimDecimalsOverflow } from '@app/helpers/decimals';

type SwapItem = {
  token: Token;
  amount: string;
};

export function useSwap(
  from: SwapItem,
  to: SwapItem,
  swapOut: string | number | undefined,
  selectedTrade: USTrade | undefined
) {
  const config = useConfig();

  const address = useAddress();
  const { contract } = useContract(config?.CONTRACTS.ROUTER, routerAbi);
  const { contract: wethContract } = useContract(
    config?.CONTRACTS.WETH,
    wethAbi
  );

  const sdk = useSDK();

  const { mutate: transferTokens } = useTransferToken(contract);

  const [{ loading, error }, swapTokens] = useAsyncFn(async () => {
    if (!from.amount || !to || !contract || !address || !wethContract) {
      return undefined;
    }

    const settings = await getSwapSettings();

    if (
      // ETH -> WETH
      from.token.contractAddress === NATIVE_TOKEN_ADDRESS &&
      to.token.contractAddress === config?.CONTRACTS.WETH
    ) {
      switch (config.modeEnv) {
        case ModeEnvs.MAINNET: {
          return sdk?.wallet.transfer(config?.CONTRACTS.WETH, +from.amount);
        }
        case ModeEnvs.TESTNET:
        default: {
          return wethContract.call('deposit', [], {
            value: numberToBigNumberFixed(+from.amount, from.token.decimals)
          });
        }
      }
    } else if (
      // WETH -> ETH
      from.token.contractAddress === config?.CONTRACTS.WETH &&
      to.token.contractAddress === NATIVE_TOKEN_ADDRESS
    ) {
      return wethContract.call('withdraw', [
        numberToBigNumberFixed(+from.amount, from.token.decimals)
      ]);
    } else if (selectedTrade && swapOut) {
      const _slippage = decToFrac(settings.slippage / 100);

      const swapParams = Router.swapCallParameters(selectedTrade, {
        allowedSlippage: new Percent(_slippage[0], _slippage[1]),
        ttl: settings.deadline * 60,
        recipient: address,
        feeOnTransfer: true
      });

      const args = [
        ...swapParams.args.slice(0, swapParams.args.length - 1),
        address,
        swapParams.args.pop()
      ];

      // Fix an issue with wrong calculated outputAmount from smart router
      if (
        swapParams.methodName ===
        'swapExactETHForTokensSupportingFeeOnTransferTokens'
      ) {
        args[0] = parseUnits(
          (+swapOut - (settings.slippage / 100) * +swapOut).toString(),
          to.token.decimals
        );
      } else {
        const _updatedWithSlippage =
          +swapOut - (settings.slippage / 100) * +swapOut;
        const _updatedWithDecimals = trimDecimalsOverflow(
          _updatedWithSlippage.toString(),
          to.token.decimals
        );

        args[1] = parseUnits(_updatedWithDecimals, to.token.decimals);
      }

      return contract.call(swapParams.methodName, args, {
        value: swapParams.value
      });
    }
  }, [contract, from, to, swapOut, wethContract, selectedTrade, config]);

  return {
    swapTokens,
    loading,
    error
  };
}
